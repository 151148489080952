<template>
  <div class="rentQi">
    <div class="search" style="display: block">
      <a-input v-model="sCity" class="search-style" placeholder="城市" />
      <a-button class="search-style" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
      <a-button type="primary" style="float: right" @click="_action()"
        >添加</a-button
      >
    </div>
    <a-table v-if="data" :pagination="false" :data-source="data" bordered>
      <a-table-column key="City" title="城市" data-index="City" />
      <a-table-column key="School" title="中心" data-index="School" />
      <a-table-column key="Amarket" title="A期间" data-index="Amarket" />
      <a-table-column key="Bmarket" title="B期间" data-index="Bmarket" />
      <a-table-column key="Cmarket" title="C期间" data-index="Cmarket" />
      <a-table-column key="Fee" title="租金" data-index="Fee" />
      <a-table-column key="MangeFee" title="管理费" data-index="MangeFee" />
      <a-table-column
        key="ReduceAmount"
        title="减免金额"
        data-index="ReduceAmount"
      />
      <a-table-column key="IsExpect" title="减免预期" data-index="IsExpect" />
      <a-table-column key="IsAgree" title="协议" data-index="IsAgree" />
      <a-table-column key="PayTime" title="支付时间" data-index="PayTime" />
      <a-table-column key="Attitude" title="对方态度" data-index="Attitude" />
      <a-table-column key="Remark" title="备注" data-index="Remark">
        <template slot-scope="text, record">
          <span style="width: 60px">{{ record.Remark }}</span>
        </template>
      </a-table-column>
      <a-table-column key="Id" title="操作">
        <template slot-scope="text, record">
          <i
            class="iconfont icon-bianji icon-btn"
            style="font-size: 20px"
            @click.stop="_action(text, record.Id)"
          ></i
          >&nbsp;&nbsp;
          <a-popconfirm
            title="您确定要删除吗?"
            @confirm="_del(record.Id)"
            okText="确定"
            cancelText="取消"
          >
            <i class="iconfont icon-shanchu- icon-btn"></i>
          </a-popconfirm>
        </template>
      </a-table-column>
    </a-table>
    <a-pagination
      v-if="total"
      style="margin-top: 35px;text-align: right;"
      @change="ChangePage"
      :pageSize="Limit"
      :total="total"
      show-less-items
    />

    <a-drawer
      :title="Id ? '修改' : '添加'"
      width="800"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <div style="margin-bottom: 60px">
        <div class="row">
          <span class="row-title">城市</span>
          <a-input class="row-input" v-model="City" placeholder="城市" />
        </div>
        <div class="row">
          <span class="row-title">中心</span>
          <a-input class="row-input" v-model="School" placeholder="中心" />
        </div>
        <div class="row">
          <span class="row-title">A期间</span>
          <a-input class="row-input" v-model="Amarket" placeholder="A期间" />
        </div>
        <div class="row">
          <span class="row-title">B期间</span>
          <a-input class="row-input" v-model="Bmarket" placeholder="B期间" />
        </div>

        <div class="row">
          <span class="row-title">C期间</span>
          <a-input class="row-input" v-model="Cmarket" placeholder="C期间" />
        </div>
        <div class="row">
          <span class="row-title">租金</span>
          <a-input class="row-input" v-model="Fee" placeholder="租金" />
        </div>
        <div class="row">
          <span class="row-title">管理费</span>
          <a-input class="row-input" v-model="MangeFee" placeholder="管理费" />
        </div>
        <div class="row">
          <span class="row-title">减免金额</span>
          <a-input
            class="row-input"
            v-model="ReduceAmount"
            placeholder="减免金额"
          />
        </div>
        <div class="row">
          <span class="row-title">减免预期</span>
          <a-input
            class="row-input"
            v-model="IsExpect"
            placeholder="是否达到减免预期"
          />
        </div>
        <div class="row">
          <span class="row-title">协议</span>
          <a-input
            class="row-input"
            v-model="IsAgree"
            placeholder="是否达成协议"
          />
        </div>
        <div class="row">
          <span class="row-title">支付时间</span>
          <a-input
            class="row-input"
            v-model="PayTime"
            placeholder="延期支付时间"
          />
        </div>
        <div class="row">
          <span class="row-title">对方态度</span>
          <a-input
            class="row-input"
            v-model="Attitude"
            placeholder="对方态度"
          />
        </div>
        <div class="row">
          <span class="row-title">备注</span>
          <a-textarea
            class="row-input"
            v-model="Remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </div>
      </div>

      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
      >
        <a-button style="marginRight: 8px" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" @click="_toAction">
          确认
        </a-button>
      </div>
    </a-drawer>
    <div v-show="spinning" style="position:fixed;left:50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer)
import moment from 'moment'
export default {
  name: 'rentQi',
  data () {
    return {
      sCity: '',
      data: '',
      Page: 0,
      Limit: 10,
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      spinning: false,
      Id: '',
      visible: false,
      City: '',
      School: '',
      Amarket: '',
      Bmarket: '',
      Cmarket: '',
      Fee: '',
      ReduceAmount: '',
      IsExpect: '',
      IsAgree: '',
      PayTime: '',
      Attitude: '',
      Remark: '',
      total: '',
      MangeFee: ''
    }
  },
  created () {
    this._loadData()
    // this._categoryList();
  },
  methods: {
    moment,
    _loadData () {
      this.spinning = true
      let self = this
      this.$axios.get(
        1085,
        {
          page: self.Page,
          limit: self.Limit,
          City: self.sCity
        },
        res => {
          self.spinning = false
          console.log(res)
          self.data = res.data.list
          self.total = res.data.count
        }
      )
    },
    _clear () {
      this.sTitle = ''
      this.sCategory = ''
      this.sLabel = ''
      this.sStatus = ''
      this.sEndDate = ''
      this.sStartDate = ''

      this._loadData()
    },

    _search () {
      this._loadData()
    },
    ChangePage (value) {
      this.Page = value
      this._loadData()
    },
    _action (text, id) {
      console.log(text)
      console.log(id)
      if (id) {
        this.City = text.City
        this.School = text.School
        this.Amarket = text.Amarket
        this.Bmarket = text.Bmarket
        this.Cmarket = text.Cmarket
        this.Fee = text.Fee
        this.ReduceAmount = text.ReduceAmount
        this.IsExpect = text.IsExpect
        this.IsAgree = text.IsAgree
        this.PayTime = text.PayTime
        this.Attitude = text.Attitude
        this.Remark = text.Remark
        this.MangeFee = text.MangeFee
        this.Id = id
      }
      this.visible = true
    },
    _del (id) {
      this.spinning = true
      this.$axios.get(
        1087,
        {
          Id: id
        },
        res => {
          this.spinning = false
          if (res.data.code == 1) {
            this._loadData()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    },
    onClose () {
      this.City = ''
      this.School = ''
      this.Amarket = ''
      this.Bmarket = ''
      this.Cmarket = ''
      this.Fee = ''
      this.ReduceAmount = ''
      this.IsExpect = ''
      this.IsAgree = ''
      this.PayTime = ''
      this.Attitude = ''
      this.Remark = ''
      this.Id = ''
      this.MangeFee = ''
      this.visible = false
    },

    _toAction () {
      let self = this
      if (!this.City) {
        this.$message.error('请输入城市')
        return false
      }
      if (!this.School) {
        this.$message.error('请输入中心')
        return false
      }
      this.spinning = true
      this.$axios.get(
        1086,
        {
          Id: self.Id,
          City: self.City,
          School: self.School,
          Amarket: self.Amarket,
          Bmarket: self.Bmarket,
          Cmarket: self.Cmarket,
          Fee: self.Fee,
          ReduceAmount: self.ReduceAmount,
          IsExpect: self.IsExpect,
          IsAgree: self.IsAgree,
          PayTime: self.PayTime,
          Attitude: self.Attitude,
          Remark: self.Remark,
          uid: self.userinfo.uid,
          username: self.userinfo.name,
          MangeFee: self.MangeFee
        },
        res => {
          this.spinning = false
          if (res.data.code == 1) {
            this._loadData()
            self.onClose()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="less">
.rentQi {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}
.search {
  margin-bottom: 20px;
  .search-style {
    display: inline-block;
    width: 120px;
    margin-right: 15px;
  }
}
.row {
  display: flex;
  margin-bottom: 20px;
  .row-title {
    display: block;
    width: 88px;
    height: 32px;
    line-height: 32px;
  }
  .row-input {
    /*flex:1;*/
    display: block;
    width: 320px;
  }
}
.upload-bth {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.images {
  width: 80px;
  height: 32px;
  margin-left: 15px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
